.nav {
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
}
.nav-menu {
  display: flex;
  text-align: center;
  justify-content: space-between;
}
.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}
.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}
.nav-item:hover:after {
  width: 100%;
  background: #ffdd40;
}
.nav-links {
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.nav-icon {
  display: none;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
@media screen and (max-width: 960px) {
  .nav {
    position: sticky;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 50px;
    left: -110%;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background: #8144e5;
    color: #fff;
    left: 0;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-icon {
    display: block;
    position: absolute;
    margin-top: -1.2em;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #8144e5;
  }
}
