@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  P {
    @apply font-light;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif";
}

html {
  scroll-behavior: smooth;
}

/*Scroll to top*/
.scroll-top {
  position: fixed;
  bottom: 2em;
  right: 4em;
  background-color: #f55353;
  color: white;
  padding: 10px;
  font-size: 20px;
}

@media (max-width: 900px) {
  .scroll-top {
    display: none;
  }
}
